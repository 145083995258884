import React from 'react'
import { Accordion, Card, Container, Button, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { textStyle } from '../../utils'
import { useSearchParams } from '../../libs/hooks'

export const AccordionModule = ({
  heading,
  subModules,
  style,
  orgId,
  payerLink,
}) => {
  const findButtonLink = subModules.find(item => item.buttonLink)
  const url = useSearchParams(findButtonLink, orgId, payerLink)

  return (
    <Container fluid className="outer p-0 m-0">
      <Accordion className="mx-auto" flush>
        <Accordion.Item>
          <Accordion.Header className="module-heading mw">
            <div className="mw mx-auto w-100">
              <h2
                className="accordion-heading"
                css={textStyle(style?.headingColor)}
              >
                {heading && heading}
              </h2>
            </div>
          </Accordion.Header>
          <Accordion.Body style={{ backgroundColor: '#ecf2f6' }}>
            <Row className="mx-auto" style={{ maxWidth: '1200px' }}>
              {subModules &&
                subModules.map(item => (
                  <Col key={item.heading} sm={12} md={12} lg={6}>
                    <Card
                      className="my-3 border-0 people-card"
                      style={{ borderRadius: '10px' }}
                    >
                      <Card.Body className="row">
                        <div className="col-3">
                          {item.imageUrl && (
                            <Card.Img src={item.imageUrl}></Card.Img>
                          )}
                        </div>
                        <div className="col-7 d-flex flex-column justify-content-center">
                          <Card.Title
                            style={{
                              fontSize: '1.4rem',
                              textTransform: 'uppercase',
                            }}
                          >
                            <strong>{item.heading}</strong>
                          </Card.Title>
                          <Card.Text>{item.subtitle}</Card.Text>
                        </div>
                        <div className="col-2 d-flex flex-column justify-content-start text-right">
                          {item.button && (
                            <Button variant="primary">{item.button}</Button>
                          )}
                          <a
                            href={url}
                            aria-label="icon"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FontAwesomeIcon
                              style={{ color: '#2067B7' }}
                              className="display-6"
                              icon={faLinkedin}
                            />
                          </a>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  )
}
export const mapAccordionModuleProps = props => {
  props.subModules &&
    props.subModules.forEach(item => {
      if (item.image) {
        item.imageUrl = item.image.file.url
      }
    })

  return props
}
